.places-near-by {
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 12px;
}

.places-near-by .heading-div {

    border-bottom: 1px solid #333;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.places-near-by .heading-div>label {
    font-size: 1.8rem;
    color: #333;
}

.places-list {
    padding: 0 0 2rem 2rem;
    font-size: 1.8rem;
}