.gallery-display-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-display-item-img {
    flex: 1;
    height: 100px;
    object-fit: cover;
}

.gallery-display-item-btn {
    display: none;
}

.gallery-display-item:hover .gallery-display-item-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: #ed1a23;
    outline: none;
    border: none;
    background: transparent;
    font-size: 2.4rem;
    transition: all .3s ease;
    cursor: pointer;
    z-index: 999;
}

.gallery-display-item-btn:hover {
    transform: scale(1.2);
}