.gallery-images {
    margin: 2rem 0;
    border: 1px solid #ccc;
    padding: 1rem;
}

.preview-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.gallery-img {
    width: 100%;
    height: 8rem;
    object-fit: cover;
}

.gallery-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.gallery-img-remove-btn {
    outline: none;
    border: none;
    background: transparent;
    position: absolute;
    color: #ed1a23;
    font-size: 2.4rem;
    display: none;
    cursor: pointer;
    transition: all .3s ease;
    border: none;
    outline: none !important;
    text-decoration: none;
}

.gallery-img-container:hover .gallery-img-remove-btn {
    display: block;
}

.gallery-img-container:hover .gallery-img-remove-btn:hover {
    transform: scale(1.2);
}

.gallery-images {
    margin: 2rem 0;
    border: 1px solid #ccc;
    padding: 1rem;
}

.preview-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.gallery-img {
    width: 100%;
    height: 4rem;
    object-fit: cover;
}

.gallery-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}