@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

#login-form-box {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    background: url("../../../public/images/login-bg.jpg") no-repeat center center;
    background-size: cover;
    backdrop-filter: blur(7px) ! important;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    font-weight: bold;
    color: #363636;
    margin: 0;
    padding: 0;

    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999999999;
}

#login-form-box .login-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

#login-form-box .login-content {
    margin: 0 auto;
    width: 700px;
    height: 440px;
    display: flex;
}

#login-form-box .login-banner {
    height: 100%;
    width: 100%;
    background: #ed106f;
    border-radius: 20px 0 0 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 0px 25px 0px 25px;
}

#login-form-box .login-banner h1 {
    font-size: 24px;
    color: #fff;
    opacity: 80%;
    font-weight: 500;
    margin-bottom: 30px;
}

#login-form-box .login-banner h3 {
    color: #fff;
    opacity: 80%;
    font-weight: 400;
    font-size: 14px;
}

#login-form-box .login-form {
    height: 100%;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 20px 20px 0;
    position: relative;
    justify-content: center;
    padding: 0px 25px 0px 25px;
}

#login-form-box .logo {
    width: 100px;
    margin-bottom: 30px;
}

#login-form-box .login-form .user {
    margin: 0px 0 30px;
    height: 70px;
    border-radius: 50%;
}

#login-form-box .login-id {
    margin-bottom: 10px;
}

#login-form-box .login-id {
    width: 100%;
    height: 45px;
    border: 1px solid #ccc;
    color: #000;
    font-weight: bold;
    border-radius: 4px;
    padding: 6px 15px;
}

#login-form-box .login-id:focus {
    outline: 0;
    transition: 0.8s;
    border: 2px solid #614784;
}

#login-form-box ::placeholder {
    color: #aaa;
    font-weight: 400
}



#login-form-box ::-ms-reveal {
    filter: invert(100%);
}

#login-form-box .orange-line {
    background: #fcb831;
    width: 40px;
    height: 2px;
    border-radius: 30px;
    margin-bottom: 30px;
}

#login-form-box button {
    width: 100%;
    height: 45px;
    border: 2px solid #130238;
    background: #130238;
    color: #fff;
    cursor: pointer;
    border-radius: 100px;
    font-weight: 400;
    margin-top: 10px;
    transition: .5s;
}

#login-form-box button:hover {
    background: #e12464;
    border: 2px solid #e12464;
    transition: .5s;
}


@media (max-width: 767px) {
    #login-form-box .login-form-box {
        backdrop-filter: blur(0);
    }

    #login-form-box .login-content {
        display: block;
        width: 100%;
        padding: 20px;
        height: auto;
    }

    #login-form-box .login-banner {
        border-radius: 20px 20px 0px 0px;
        padding: 30px 20px 20px 20px;
        width: auto;
    }

    #login-form-box .login-form {
        border-radius: 0px 0px 20px 20px;
        padding: 30px 20px 30px 20px;
        width: auto;
    }
}