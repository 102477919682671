:root {
  --border-color: #34425a;
  --input-bg: #f5f8ff;
}

.wrapper {
  margin: 2rem 0;
  background-color: #fff;
  padding: .4rem;
  border-radius: 12px;
  box-shadow: 0 0.2rem 0.3rem #666;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.drop-area {
  color: var(--border-color);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 2rem;
  border: 3px dashed var(--border-color);
  border-radius: 12px;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: var(--input-bg);
  position: relative;
}

.drop-area-hover {
  opacity: 0.75;
  cursor: pointer;
}

.drag-drop-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}