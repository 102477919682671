.custom-modal {
    max-width: 40vw;
    width: 40vw;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: .75rem;
}

.content {
    background-color: #fff;
    padding: 1rem;
    width: 100%;
}